import { useState } from 'react';
import classNames from 'classnames';
import downCaret from "../assets/images/down-caret.png";
import upCaret from "../assets/images/up-caret.png";
import styles from './SearchFilter.module.css';

const SearchFilter = (props) => {
	const [open, setOpen] = useState(false);

	const update = (option) => {
		props.update(option.label, option.value);
		setOpen(false);
	};

	return (
		<div className={classNames(styles.container, props.className)} style={props.style}>
			<div className={styles.button} onClick={() => setOpen(!open)}>
				{props.label}
                {
                    !open && (
                        <img className={styles.downCaret} src={downCaret} alt="Down Caret"/>
                    )
                }
                {
                    open && (
                        <img className={styles.upCaret} src={upCaret} alt="Up Caret"/>
                    )
                }
			</div>
			{
				open && (
					<div className={styles.options}>
						{
							props.options.map(option => (
								<div className={classNames(styles.option, {[styles.selected]: option.value === props.value})} key={option.value}
									 onClick={() => update(option)}>
									{option.label}
								</div>
							))
						}
					</div>
				)
			}
		</div>
	)
};

export default SearchFilter;
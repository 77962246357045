import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import forgotPassword from '../assets/images/forgot-password.jpg';
import lock from '../assets/images/lock.png';
import styles from './ResetPassword.module.css';
import { verifyPasswordResetTokenApi, resetPasswordApi } from '../api/auth.js';
import FormHeader from '../components/form/FormHeader.jsx';
import FormDescription from '../components/form/FormDescription.jsx';
import FormInput from '../components/form/FormInput.jsx';
import FormButton from '../components/form/FormButton.jsx';
import FormError from '../components/form/FormError.jsx';

const ResetPassword = () => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		password: '',
		confirmPassword: '',
	});
	const initialErrorObject = {
		password: false,
		confirmPassword: false
	};
	const [errorObject, setErrorObject] = useState(initialErrorObject);
	const inputsMessagesErrorObject = {
		passwordLength: 'Password must be at least 6 characters',
		password: 'Missing password',
		confirmPassword: 'Passwords do not match',
	};
	const [isTokenValid, setIsTokenValid] = useState(false);
	const [resetInProgress, setResetInProgress] = useState(false);
	const [resetSuccessful, setResetSuccessful] = useState(false);

	const checkForEmptyInputs = () => {
		setErrorObject(initialErrorObject);
		const newErrorObject = structuredClone(initialErrorObject);
		let foundError = false;
		for(const key of Object.keys(formData)){
			if(!formData[key] && key !== 'confirmPassword'){
				newErrorObject[key] = inputsMessagesErrorObject[key];
				foundError = true;
			}
		}
		setErrorObject(newErrorObject);
		return foundError;
	};

	const checkPasswordLength = () => {
		if(formData.password.length < 6){
			setErrorObject((prevState) => ({...prevState, password: inputsMessagesErrorObject.passwordLength}));
			return false;
		}
		return true;
	};

	const checkPasswordsMatch = () => {
		if(formData.password !== formData.confirmPassword){
			setErrorObject((prevState) => ({...prevState, confirmPassword: inputsMessagesErrorObject.confirmPassword}));
			return false;
		}
		return true;
	};

	const submit = async (event) => {
		event.preventDefault();
		setErrorObject(initialErrorObject);
		setResetInProgress(true);
		if(checkForEmptyInputs()){
			setResetInProgress(false);
			return;
		}
		if(!checkPasswordLength() || !checkPasswordsMatch()){
			setResetInProgress(false);
			return;
		}
		const response = await resetPasswordApi(formData, searchParams.get('token'));
		let body;
		try{
			body = await response.json();
		}
		catch(e){}
		if(response && response.ok){
			localStorage.setItem('api-token', body.data.token);
			setResetSuccessful(true);
			setTimeout(() => {
				navigate('/dashboard');
			}, 1500)
		}
		else{
			const error = body?.error ? body.error : 'Password reset failed';
			setErrorObject((prevState) => ({...prevState, apiError: error}));
		}
		setResetInProgress(false);
	};

	useEffect(() => {
		document.title = 'Reset Password';
		const verify = async () => {
			const response = await verifyPasswordResetTokenApi(searchParams.get('token'));
			if(response && response.ok){
				setIsTokenValid(true);
			}
		};
		verify();
	}, []);

	useEffect(() => {
		const errorElements = document.getElementsByClassName('error');
		if(errorElements.length > 0){
			errorElements[0].scrollIntoView({
				block: 'start',
				behavior: 'smooth',
			});
		}
	}, [errorObject]);

	return (
		<div className={styles.container}>
			<div className={styles.formContainer}>
				{
					!resetSuccessful && isTokenValid && (
						<form className={styles.form} onSubmit={submit}>
							<FormHeader className={styles.header} text="Reset your password"/>
							<FormDescription className={styles.description} text="Please enter a new password"/>
							<FormInput className={styles.singleLineInput} label="Password" type="password" placeholder="****************" value={formData.password}
									   update={(event) => setFormData({...formData, password: event.target.value})} error={errorObject.password} required/>
							<FormInput className={styles.singleLineInput} label="Confirm password" type="password" placeholder="****************" value={formData.confirmPassword}
									   update={(event) => setFormData({...formData, confirmPassword: event.target.value})} error={errorObject.confirmPassword} required/>
							{
								errorObject.apiError && (
									<FormError error={errorObject.apiError}/>
								)
							}
							<div className={styles.formBottom}>
								<FormButton text="Reset password" disabled={resetInProgress}/>
							</div>
						</form>
					)
				}
				{
					!resetSuccessful && !isTokenValid && (
						<FormDescription className={styles.invalidTokenDescription} text="The password reset link you provided is invalid or has expired"/>
					)
				}
				{
					resetSuccessful && (
						<div>
							<img className={styles.shieldCheckmark} src={lock} alt="Lock"/>
							<h2 className={styles.resetSuccessfulHeader}>
								Success!
							</h2>
							<FormDescription text="You have successfully reset your password. You will be redirected soon"/>
						</div>
					)
				}
			</div>
			<div className={styles.banner}>
				<img className={styles.image} src={forgotPassword} alt="Forgot Password"/>
			</div>
		</div>
	);
};

export default ResetPassword;
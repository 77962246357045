import notFound from '../assets/images/not-found.jpg';
import styles from './NotFound.module.css';
import ButtonLink from '../components/ButtonLink.jsx';

const NotFound = () => {
	return (
		<div className={styles.container}>
			<div className={styles.errorCode}>
				404
			</div>
			<div className={styles.text}>
				Oops, something went wrong
			</div>
			<ButtonLink href="/dashboard" text="Back to homepage" dark={true}/>
			<img className={styles.image} src={notFound} alt="Not Found"/>
		</div>
	)
};

export default NotFound;
import classNames from 'classnames';
import downCaret from '../../assets/images/down-caret.png';
import styles from './FormDropdown.module.css';
import FormError from './FormError.jsx';

const FormDropdown = (props) => {
	const selectClasses = classNames(styles.select, {
		[styles.noImage]: !props.image,
		[styles.error]: props.error
	});

	return (
		<div className={classNames(styles.container, props.className)} style={props.style}>
			<label className={styles.label}>
				{props.label}
				{props.required && (<span className={styles.asterisk}>*</span>)}
			</label>
			<div className={styles.dropdownContainer}>
				<select className={selectClasses} style={props.dropdownWidth && {width: props.dropdownWidth}} value={props.value} onChange={props.update}>
					{
						props.options && props.options.map(option =>
							<option value={option.value} key={option.value}>
								{option.name}
							</option>
						)
					}
				</select>
				{
					props.image && (
						<img className={styles.image} src={props.image} alt="Custom Icon"/>
					)
				}
				<img className={styles.downCaret} src={downCaret} alt="Down Caret"/>
				{
					props.error && (
						<FormError error={props.error}/>
					)
				}
			</div>
		</div>
	)
};

export default FormDropdown;
import { useState } from 'react';
import classNames from 'classnames';
import close from '../../assets/images/close.png';
import styles from './DonarsModal.module.css';
import DonationRecord from '../fundraiser/DonationRecord.jsx';
import Button from '../Button.jsx';

const DonarsModal = (props) => {
	const [view, setView] = useState(props.view);

	const sortByDate = (a, b) => {
		if(a.created_at > b.created_at){
			return -1;
		}
		else if(a.created_at < b.created_at){
			return 1;
		}
		return 0;
	};

	const sortByAmount = (a, b) => {
		return b.amount - a.amount;
	};

	return (
		<div className={styles.modal}>
			<div className={styles.container}>
				<div className={styles.header}>
					{props.donations.length} Donations
				</div>
				<img className={styles.close} src={close} alt="Close" onClick={props.close}/>
				<div className={styles.nav}>
					<span className={classNames(styles.view, {[styles.selected]: view === 'newest'})} onClick={() => setView('newest')}>
						All Donors
					</span>
					<span className={classNames(styles.view, {[styles.selected]: view === 'amount'})} onClick={() => setView('amount')}>
						Top Donors
					</span>
				</div>
				<div className={styles.donations}>
					{
						props.donations.sort(view === 'newest' ? sortByDate : sortByAmount).map(donation => (
							<DonationRecord donation={donation} key={'donation-' + donation.id}/>
						))
					}
				</div>
				<Button className={styles.button} text="Close" onClick={props.close}/>
			</div>
		</div>
	)
};

export default DonarsModal;
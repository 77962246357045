import { Navigate, Outlet, useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth.js';

const ProtectedRoute = () => {
	const location = useLocation();
	const { authenticated, user } = useAuth();

	if(authenticated === undefined){
		return null;
	}
	else{
		return authenticated ? <Outlet context={{user: user}}/> : <Navigate to="/fundraisers" state={{from: location}}/> ;
	}
}

export default ProtectedRoute;
import styles from './Link.module.css';
import classNames from 'classnames';

const Link = (props) => {
	return (
		<a className={classNames(styles.link, props.className)} href={props.href}>
			{props.text}
		</a>
	);
};

export default Link;
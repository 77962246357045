import classNames from 'classnames';
import warning from '../../assets/images/warning.png';
import styles from './FormError.module.css';

const FormError = (props) => {
	return (
		<span className={classNames(styles.error, props.className, 'error')}>
			<img className={styles.warning} src={warning} alt="Warning"/>
			{props.error}
		</span>
	)
};

export default FormError;
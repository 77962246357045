import classNames from 'classnames';
import styles from './FormHeader.module.css';

const FormHeader = (props) => {
	return (
		<h1 className={classNames(styles.header, props.className)}>
			{props.text}
		</h1>
	)
}

export default FormHeader;
import classNames from 'classnames';
import dollarCircleOrange from './../../assets/images/dollar-circle-orange.png';
import styles from './FormDonateMoneyInput.module.css';
import FormError from './FormError.jsx';

const FormDonateMoneyInput = (props) => {
	const inputClasses = classNames(styles.input, {
		[styles.error]: props.error
	});

	return (
		<div className={classNames(styles.container, props.className)} style={props.style}>
			<div className={styles.inputContainer}>
				<input className={inputClasses} type="number" pattern="\d*" min="1" max="100000" step="0.01" placeholder="0" style={props.inputWidth && {width: props.inputWidth}}
					   value={props.value} onChange={props.update} required disabled={props.disabled === true}/>
				<img className={styles.dollarCircleOrange} src={dollarCircleOrange} alt="Dollar Sign"/>
				{
					props.error && (
						<FormError error={props.error}/>
					)
				}
			</div>
		</div>
	)
};

export default FormDonateMoneyInput;
import classNames from 'classnames';
import styles from './FormDescription.module.css';

const FormDescription = (props) => {
	return (
		<div className={classNames(styles.description, props.className)}>
			{props.text}
		</div>
	)
}

export default FormDescription;
import { useState } from 'react';
import classNames from 'classnames';
import close from '../../assets/images/close.png';
import link from '../../assets/images/link.png';
import checkmarkGray from '../../assets/images/checkmark-gray.png';
import email from '../../assets/images/email.png';
import x from '../../assets/images/x.png';
import facebook from '../../assets/images/facebook.png';
import styles from './ShareModal.module.css';
import Button from '../Button.jsx';

const ShareModal = (props) => {
	const [copyButtonClass, setCopyButtonClass] = useState(styles.copyButton);
	const [copyButtonText, setCopyButtonText] = useState('Copy link');
	const [copyButtonIcon, setCopyButtonIcon] = useState(link);

	const copyLink = () => {
		navigator.clipboard.writeText(window.location.href);
		setCopyButtonClass(styles.copiedButton)
		setCopyButtonText('Copied!');
		setCopyButtonIcon(checkmarkGray);
	};

	const emailLink = () => {
		window.open('mailto:?body=' + window.location.href);
	};

	const shareViaX = () => {
		window.open('https://x.com/intent/post?url=' + window.location.href);
	};

	const shareViaFacebook = () => {
		window.open('https://www.facebook.com/sharer/sharer.php?u=' + window.location.href)
	};

	return (
		<div className={styles.modal}>
			<div className={styles.container}>
				<div className={styles.header}>
					Share
				</div>
				<img className={styles.close} src={close} alt="Close" onClick={props.close}/>
				<div className={styles.buttons}>
					<Button className={classNames(styles.button, copyButtonClass)} text={copyButtonText} image={copyButtonIcon} onClick={copyLink}/>
					<Button className={classNames(styles.button, styles.emailButton)} text="Email" image={email} onClick={emailLink}/>
					<Button className={classNames(styles.button, styles.xButton)} text="X" image={x} onClick={shareViaX}/>
					<Button className={classNames(styles.button, styles.facebookButton)} text="Facebook" image={facebook} onClick={shareViaFacebook}/>
				</div>
			</div>
		</div>
	)
};

export default ShareModal;
import { useState } from 'react';
import classNames from 'classnames';
import togglePassword from '../../assets/images/toggle-password.png';
import styles from './FormInput.module.css';
import FormError from './FormError.jsx';

const FormInput = (props) => {
	const [currentInputType, setCurrentInputType] = useState(props.type);
	const inputClasses = classNames(styles.input, {
		[styles.password]: props.type === 'password',
		[styles.error]: props.error,
		[styles.disabled]: props.disabled === true
	});
	const showHidePassword = () => {
		setCurrentInputType(currentInputType === 'text' ? 'password' : 'text')
	};

	return (
		<div className={classNames(styles.container, props.className)} style={props.style}>
			<label className={styles.label}>
				{props.label}
				{props.required && (<span className={styles.asterisk}>*</span>)}
			</label>
			<div className={styles.inputContainer}>
				<input className={inputClasses} type={currentInputType} maxLength={props.maxlength || 35} placeholder={props.placeholder} style={props.inputWidth && {width: props.inputWidth}}
					   value={props.value} onChange={props.update} disabled={props.disabled === true}/>
				{
					props.type === 'password' && (
						<img className={styles.togglePassword} src={togglePassword} alt="Toggle Password" onClick={showHidePassword}/>
					)
				}
				{
					props.error && (
						<FormError error={props.error}/>
					)
				}
			</div>
	   </div>
	)
};

export default FormInput;
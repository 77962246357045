import classNames from 'classnames';
import magnifyingGlass from '../assets/images/magnifying-glass.png';
import styles from './SearchInput.module.css';

const SearchInput = (props) => {
	return (
		<div className={classNames(styles.container, props.className)} style={props.style}>
			<div className={styles.inputContainer}>
				<input className={styles.input} type="text" maxLength={props.maxlength || 35} placeholder={props.placeholder} style={props.inputWidth && {width: props.inputWidth}}
					   value={props.value} onChange={props.update}/>
				<img className={styles.magnifyingGlass} src={magnifyingGlass} alt="Magnifying Glass"/>
			</div>
		</div>
	)
};

export default SearchInput;
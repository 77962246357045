const createImage = (url) => {
	return new Promise((resolve, reject) => {
		const image = new Image();
		image.addEventListener('load', () => resolve(image));
		image.addEventListener('error', (error) => reject(error));
		image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
		image.src = url;
	});
}

const getRadianAngle = (degreeValue) => {
	return (degreeValue * Math.PI) / 180;
};

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export default async function getEditedImage(imageSrc, pixelCrop, rotation = 0){
	const image = await createImage(imageSrc);
	const canvas = document.createElement('canvas');
	const ctx = canvas.getContext('2d');

	const imageWidth = (image).width;
	const imageHeight = (image).height;
	const maxSize = Math.max(imageWidth, imageHeight);
	const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

	// set each dimensions to double largest dimension to allow for a safe area for the
	// image to rotate in without being clipped by canvas context
	canvas.width = safeArea;
	canvas.height = safeArea;

	ctx.fillStyle = 'white';
	ctx.fillRect(0, 0, canvas.width, canvas.height);

	// translate canvas context to a central location on image to allow rotating around the center.
	ctx.translate(safeArea / 2, safeArea / 2);
	ctx.rotate(getRadianAngle(rotation));
	ctx.translate(-safeArea / 2, -safeArea / 2);

	// draw rotated image and store data.
	ctx.drawImage(image, safeArea / 2 - imageWidth * 0.5, safeArea / 2 - imageHeight * 0.5);
	const data = ctx.getImageData(0, 0, safeArea, safeArea);

	// set canvas width to final desired crop size - this will clear existing context
	canvas.width = pixelCrop.width;
	canvas.height = pixelCrop.height;

	ctx.fillStyle = 'white';
	ctx.fillRect(0, 0, canvas.width, canvas.height);

	// paste generated rotate image with correct offsets for x,y crop values.
	ctx.putImageData(data, 0 - safeArea / 2 + imageWidth * 0.5 - pixelCrop.x, 0 - safeArea / 2 + imageHeight * 0.5 - pixelCrop.y);

	// As a blob
	return new Promise((resolve) => {
		canvas.toBlob((file) => {
			resolve({
				url: URL.createObjectURL(file),
				base64Data: canvas.toDataURL('image/jpeg'),
			});
		}, 'image/jpeg');
	});
}
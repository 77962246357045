import FileResizer from 'react-image-file-resizer';
import camera from '../../assets/images/camera.png';
import buildingGray from '../../assets/images/building-gray.png'
import styles from './ImageSection.module.css';

const ImageSection = (props) =>{
	const openUploadFile = () => {
		document.getElementById(props.type === 'personal' ? 'upload-personal-image' : 'upload-organization-image').click();
	};

	const readFile = (file) => {
		return new Promise((resolve) => {
			const reader = new FileReader();
			reader.addEventListener('load', () => resolve(reader.result), false);
			reader.readAsDataURL(file);
		});
	};

	const resizeFile = (file) => {
		return new Promise((resolve) => {
			FileResizer.imageFileResizer(file, 2000, 2000, 'JPEG', 100, 0, (uri) => {
				resolve(uri);
			}, 'file');
		});
	};

	const onFileChange = async(event) => {
		if(event.target.files && event.target.files.length > 0) {
			const file = event.target.files[0];
			let resizedFile;
			try {
				resizedFile = await resizeFile(file);
			} catch {
				resizedFile = file;
			}
			const imageDataUrl = await readFile(resizedFile);
			props.setImageBase64(imageDataUrl);
			props.setShowPictureModal(true);
		}
	};

	return (
		<div>
			<input id={props.type === 'personal' ? 'upload-personal-image' : 'upload-organization-image'} className={styles.uploadImageInput} type="file" accept="image/png, image/jpeg"
				   onChange={onFileChange} onClick={(event) => event.target.value=''}/>
			<div className={styles.imageSection} onClick={openUploadFile}>
				{
					props.formData.pictureUrl && (
						<img className={styles.image} src={process.env.REACT_APP_CLOUDFRONT_S3_DOMAIN + props.formData.pictureUrl} alt={props.type === 'personal' ? 'Profile' : 'Organization'}/>
					)
				}
				{
					!props.formData.pictureUrl && (
						<span className={styles.imagePlaceholder}>
							{
								props.type === 'personal' && props.user?.first_name && props.user?.last_name && (
									props.user.first_name.slice(0, 1).toUpperCase() + ' ' + props.user.last_name.slice(0, 1).toUpperCase()
								)
							}
							{
								props.type === 'organization' && (
									<img className={styles.imagePlaceholderIcon} src={buildingGray} alt="Building"/>
								)
							}
						</span>
					)
				}
				<div className={styles.overlay}/>
				<img className={styles.camera} src={camera} alt="Camera"/>
			</div>
		</div>
	);
};

export default ImageSection;
import classNames from 'classnames';
import styles from './Button.module.css';

const Button = (props) => {
	const classes = classNames(styles.button, props.className, {
		[styles.dark]: props.dark,
		[styles.disabled]: props.disabled
	});

	return (
		<button className={classes} style={props.style} type="button" disabled={props.disabled} onClick={props.onClick}>
			{
				props.image && (
					<img className={styles.image} src={props.image} alt="Custom Icon"/>
				)
			}
			{props.text}
		</button>
	);
};

export default Button;
const findArrayIndex = (arrayOfObjects, propertyKey, propertyValue, fuzzyMatch) => {
	for(let i = 0; i < arrayOfObjects.length; i++){
		if(!fuzzyMatch){
			if(arrayOfObjects[i][propertyKey] === propertyValue){
				return i;
			}
		}
		else{
			if(arrayOfObjects[i][propertyKey].indexOf(propertyValue) !== -1){
				return i;
			}
		}
	}
	return false;
};

export default findArrayIndex;
import classNames from 'classnames';
import checkmarkWhite from '../../assets/images/checkmark-white.png';
import styles from './FormCheckbox.module.css';

const FormCheckbox = (props) => {
	const randomNumber = Math.random() * (999999999 - 100000000) + 100000000;
	const inputId = 'form-checkbox-' + randomNumber;

	return (
		<div className={classNames(styles.container, props.className)} style={props.style}>
			<input id={inputId} className={styles.checkbox} style={{backgroundImage: `url(${checkmarkWhite})`}} type="checkbox" checked={props.value} onChange={props.update}/>
			<label className={styles.label} htmlFor={inputId}>
				{props.label}
			</label>
		</div>
	)
};

export default FormCheckbox;
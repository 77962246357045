import styles from './Footer.module.css'

const Footer = () => {
	const currentYear = new Date().getFullYear();

	return (
		<div className={styles.footer}>
			Copyright ©{currentYear} Giverist. All Rights Reserved.
		</div>
	)
};

export default Footer;
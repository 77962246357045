import classNames from 'classnames';
import styles from './FormTextEditor.module.css';
import FormError from './FormError.jsx';

const FormTextEditor = (props) => {
	const textareaClasses = classNames(styles.textarea, {
		[styles.error]: props.error
	});

	return (
		<div className={classNames(styles.container, props.className)} style={props.style}>
			<label className={styles.label}>
				{props.label}
				{props.required && (<span className={styles.asterisk}>*</span>)}
			</label>
			<div className={styles.textareaContainer}>
				<textarea className={textareaClasses} maxLength={props.maxlength || 10000} placeholder={props.placeholder} style={props.textareaWidth && {width: props.textareaWidth}}
					   value={props.value} onChange={props.update}/>
				<span className={styles.charactersCount}>
					{props.value?.length || 0} / {props.maxlength || 10000}
				</span>
				{
					props.error && (
						<FormError error={props.error}/>
					)
				}
			</div>
		</div>
	)
};

export default FormTextEditor;
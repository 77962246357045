import classNames from 'classnames';
import checkmarkCircleGreen from '../../assets/images/checkmark-circle-green.png';
import styles from './FormButtonSuccess.module.css';

const FormButtonSuccess = (props) => {
	const classes = classNames(styles.button, props.className);

	return (
		<button className={classes} style={props.style} type="button">
			{props.text}
			<img className={styles.checkmark} src={checkmarkCircleGreen} alt="Checkmark"/>
		</button>
	);
};

export default FormButtonSuccess;
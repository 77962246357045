import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { loadStripe } from '@stripe/stripe-js';
import { useStripe, useElements, Elements, PaymentElement } from '@stripe/react-stripe-js';
import close from '../../assets/images/close.png';
import coinJar from '../../assets/images/coin-jar.png';
import styles from './DonateModal.module.css';
import { submitDonationApi } from '../../api/fundraisers.js';
import formatNumber from '../../utils/formatNumber.js';
import Button from '../Button.jsx';
import FormDonateMoneyInput from '../form/FormDonateMoneyInput.jsx';
import FormInput from '../form/FormInput.jsx';
import FormTextEditor from '../form/FormTextEditor.jsx';
import FormError from '../form/FormError.jsx';
import FormButton from '../form/FormButton.jsx';

const Form = (props) => {
	const { fundraiserId } = useParams();
	const stripe = useStripe();
	const stripeElements = useElements();
	const stripePaymentElementOptions = {
		fields: {
			billingDetails: {
				address: {
					country: 'never'
				}
			}
		}
	};
	const [formData, setFormData] = useState({
		amount: '',
		email: '',
		displayName: '',
		comment: ''
	});
	const initialErrorObject = {
		amount: false,
		email: false,
		apiError: false
	};
	const [errorObject, setErrorObject] = useState(initialErrorObject);
	const inputsMessagesErrorObject = {
		amount: 'Not a valid amount',
		email: 'Required'
	};
	const [submissionInProgress, setSubmissionInProgress] = useState(false);

	const updateAmount = (event) => {
		setFormData({...formData, amount: event.target.value});
		props.setDonateAmount(Math.round(event.target.value * 100));
	};

	const checkForEmptyInputs = () => {
		setErrorObject(initialErrorObject);
		const newErrorObject = structuredClone(initialErrorObject);
		let foundError = false;
		for(const key of Object.keys(formData)){
			if(key !== 'displayName' && key !== 'comment' && !formData[key]){
				newErrorObject[key] = inputsMessagesErrorObject[key];
				foundError = true;
			}
		}
		setErrorObject(newErrorObject);
		return foundError;
	};

	const submit = async(event) => {
		event.preventDefault();
		setErrorObject(initialErrorObject);
		setSubmissionInProgress(true);
		if(checkForEmptyInputs()){
			setSubmissionInProgress(false);
			return;
		}
		if(!stripe || !stripeElements){
			setErrorObject((prevState) => ({...prevState, apiError: 'Stripe loading error'}));
			setSubmissionInProgress(false);
			return;
		}
		const submitPaymentResult = await stripeElements.submit();
		if(submitPaymentResult.error){
			setErrorObject((prevState) => ({...prevState, apiError: 'Payment error'}));
			setSubmissionInProgress(false);
			return;
		}
		const { confirmationToken, error: createConfirmationTokenError } = await stripe.createConfirmationToken({
			elements: stripeElements,
			params: {
				payment_method_data: {
					billing_details: {
						address: {
							country: 'US'
						}
					}
				}
			}
		});
		if(createConfirmationTokenError){
			setErrorObject((prevState) => ({...prevState, apiError: 'Stripe create confirmation token error'}));
			setSubmissionInProgress(false);
			return;
		}
		const submitDonationResponse = await submitDonationApi(fundraiserId, formData, confirmationToken.id, confirmationToken.payment_method_preview?.card?.last4);
		let body;
		try{
			body = await submitDonationResponse.json();
		}
		catch(e){}
		if(submitDonationResponse && submitDonationResponse.ok){
			props.setDonatedAmount(formData.amount);
			props.setDonated(true);
		}
		else{
			const error = body?.error ? body.error : 'Donation submission failed';
			setErrorObject((prevState) => ({...prevState, apiError: error}));
		}
		setSubmissionInProgress(false);
	};

	return (
		<form className={styles.form} onSubmit={submit}>
			<div className={styles.formInputs}>
				<FormDonateMoneyInput className={styles.amountInput} value={formData.amount} update={updateAmount} error={errorObject.amount}/>
				<PaymentElement options={stripePaymentElementOptions}/>
				<FormInput className={styles.input} label="Email address" type="email" placeholder="ie. johnsmith@gmail.com" value={formData.email}
						   update={(event) => setFormData({...formData, email: event.target.value})} error={errorObject.email} required/>
				<FormInput className={styles.input} label="Display name" type="text" placeholder="Optional" value={formData.displayName}
						   update={(event) => setFormData({...formData, displayName: event.target.value})}/>
				<FormTextEditor className={styles.textEditor} label="Comment" placeholder="500 characters max" maxlength={500} value={formData.comment}
								update={(event) => setFormData({...formData, comment: event.target.value})}/>
			</div>
			{
				errorObject.apiError && (
					<FormError error={errorObject.apiError}/>
				)
			}
			<div className={classNames(styles.buttons, {[styles.buttonsApiError]: errorObject.apiError})}>
				<Button className={styles.button} text="Close" onClick={props.close}/>
				<FormButton className={styles.button} style={{marginLeft: 'auto'}} text="Donate" disabled={submissionInProgress}/>
			</div>
		</form>
	)
};

const DonateModal = (props) => {
	const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
	const stripeFontDomain = process.env.REACT_APP_FRONT_END_DOMAIN === 'http://localhost:3000' ? 'https://giveristdev.com' : process.env.REACT_APP_FRONT_END_DOMAIN;
	const [stripeElementsOptions, setStripeElementsOptions] = useState({
		mode: 'payment',
		amount: 50,
		currency: 'usd',
		fonts: [{
			family: 'Gotham',
			src: 'url(https://giveristdev.com/Gotham-Book.otf)'
		}],
		appearance: {
			variables: {
				colorText: '#1F363D80',
				borderRadius: '500px'
			},
			rules: {
				'.Tab': {
					padding: '10px 20px',
					color: '#1F363D80',
					boxShadow: 'none',
				},
				'.Tab--selected, .Tab--selected:hover, .Tab--selected:focus': {
					color: '#1F363D80',
					border: '1px solid #40798C !important',
					outline: 'none',
					boxShadow: '0 3px 12px 0 #40798C12'
				},
				'.Block': {
					padding: '18px 16px',
					borderRadius: '10px'
				},
				'.Label': {
					margin: '4px 0 6px 8px',
					color: '#1F363D80',
					fontFamily: 'Gotham',
					fontSize: '14px',
					fontWeight: 500
				},
				'.Input': {
					height: '16px',
					padding: '18px 20px',
					color: '#1F363D',
					borderRadisu: '500px',
					boxShadow: 'none'
				},
				'.Input::placeholder': {
					color: '#1F363D80'
				},
				'.Input:focus': {
					border: '1px solid #40798C',
					outline: 'none',
					boxShadow: '0 3px 12px 0 #40798C12'
				},
				'.Error': {
					marginTop: '8px',
					marginLeft: '10px'
				}
			}
		}
	});
	const [donateAmount, setDonateAmount] = useState(0);
	const [donated, setDonated] = useState(false);
	const [donatedAmount, setDonatedAmount] = useState(0);

	useEffect(() => {
		if(donateAmount > 50){
			setStripeElementsOptions({...stripeElementsOptions, amount: donateAmount});
		}
	}, [donateAmount]);

	return (
		<div className={styles.modal}>
			<div className={classNames(styles.container, {[styles.donated]: donated})}>
				<div className={styles.header}>
					Donate now
				</div>
				<img className={styles.close} src={close} alt="Close" onClick={props.close}/>
				{
					!donated ? (
						<Elements stripe={stripePromise} options={stripeElementsOptions}>
							<Form props={props} setDonateAmount={setDonateAmount} setDonated={setDonated} setDonatedAmount={setDonatedAmount} close={props.close}/>
						</Elements>
					) : (
						<div>
							<img className={styles.coinJar} src={coinJar} alt="Coin Jar"/>
							<div className={styles.donatedHeader}>
								Thank you for your donation!
							</div>
							<div className={styles.donatedText}>
								Your donation of <span className={styles.donatedAmount}>${formatNumber(donatedAmount)}</span> has been successfully processed.
								Thank you for supporting <span className={styles.donatedOrganization}>{props.organization.name}</span>.  A receipt has been sent to your e-mail address
							</div>
							<Button className={styles.donatedButton} text="Close" onClick={props.close}/>
						</div>
					)
				}
			</div>
		</div>
	)
};

export default DonateModal;
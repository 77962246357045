export default function getFundraiserStatus(startAt, endAt){
    const currentDatetime = new Date();
    const startDatetime = new Date(startAt);
    const endDatetime = new Date(endAt);
    if(currentDatetime >= startDatetime && currentDatetime < endDatetime){
        return {
            status: 'In progress',
            statusClassName: 'inProgress'
        };
    }
    else if(currentDatetime >= startDatetime && currentDatetime >= endDatetime){
        return {
            status: 'Ended',
            statusClassName: 'ended'
        };
    }
    else if(currentDatetime < startDatetime){
        return {
            status: 'Not started',
            statusClassName: 'notStarted'
        };
    }
};
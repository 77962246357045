import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import login from '../assets/images/login.jpg';
import heart from '../assets/images/heart.png';
import rightArrow from '../assets/images/right-arrow.png';
import styles from './Login.module.css';
import { loginApi } from '../api/auth.js';
import Link from '../components/Link.jsx';
import ButtonLink from '../components/ButtonLink.jsx';
import FormHeader from '../components/form/FormHeader.jsx';
import FormInput from '../components/form/FormInput.jsx';
import FormButton from '../components/form/FormButton.jsx';
import FormError from '../components/form/FormError.jsx';

const Login = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [formData, setFormData] = useState({
		email: '',
		password: ''
	});
	const initialErrorObject = {
		email: false,
		password: false,
		login: false
	};
	const [errorObject, setErrorObject] = useState(initialErrorObject);
	const inputsMessagesErrorObject = {
		email: 'Missing email',
		password: 'Missing password',
		login: 'Invalid login'
	};
	const [loginInProgress, setLoginInProgress] = useState(false);

	const checkForEmptyInputs = () => {
		setErrorObject(initialErrorObject);
		const newErrorObject = structuredClone(initialErrorObject);
		let foundError = false;
		for(const key of Object.keys(formData)){
			if(!formData[key]){
				newErrorObject[key] = inputsMessagesErrorObject[key];
				foundError = true;
			}
		}
		setErrorObject(newErrorObject);
		return foundError;
	};

	const submit = async (event) => {
		event.preventDefault();
		setErrorObject(initialErrorObject);
		setLoginInProgress(true);
		if(checkForEmptyInputs()){
			setLoginInProgress(false);
			return;
		}
		const response = await loginApi(formData);
		let body;
		try{
			body = await response.json();
		}
		catch(e){}
		if(response && response.ok){
			localStorage.setItem('api-token', body.data.token);
			const redirectUrl = location.state?.from || '/dashboard';
			navigate(redirectUrl);
		}
		else{
			const error = body?.error ? body.error : 'Login failed';
			setErrorObject((prevState) => ({...prevState, apiError: error}));
		}
		setLoginInProgress(false);
	};

	useEffect(() => {
		document.title = 'Login';
	}, []);

	useEffect(() => {
		const errorElements = document.getElementsByClassName('error');
		if(errorElements.length > 0){
			errorElements[0].scrollIntoView({
				block: 'start',
				behavior: 'smooth',
			});
		}
	}, [errorObject]);

	return (
		<div className={styles.container}>
			<div className={styles.formContainer}>
				<form className={styles.form} onSubmit={submit}>
					<img className={styles.heart} src={heart} alt="Heart"/>
					<FormHeader className={styles.header} text="Welcome back to GIVERIST!"/>
					<FormInput className={styles.singleLineInput} label="Email" type="email" placeholder="ie. johnsmith@gmail.com" value={formData.email}
							   update={(event) => setFormData({...formData, email: event.target.value})} error={errorObject.email}/>
					<FormInput className={styles.singleLineInput} label="Password" type="password" placeholder="****************" value={formData.password}
							   update={(event) => setFormData({...formData, password: event.target.value})} error={errorObject.password}/>
					{
						errorObject.apiError && (
							<FormError error={errorObject.apiError}/>
						)
					}
					<div className={styles.formBottom}>
						<Link className={styles.forgotPasswordLink} href="/forgot-password" text="Forgot password?"/>
						<FormButton className={styles.button} disabled={loginInProgress}>
							<img src={rightArrow} alt="Right Arrow"/>
						</FormButton>
					</div>
					<div className={styles.divider}/>
					<a className={styles.receiptsLink} href="/receipts">
						Request donation receipts
					</a>
				</form>
			</div>
			<div className={styles.banner}>
				<img className={styles.image} src={login} alt="Login"/>
				<div className={styles.bottomBar}>
					<span className={styles.bottomBarText}>
						Join GIVERIST
					</span>
					<ButtonLink className={styles.signupButton} href="/signup" text="Sign up"/>
				</div>
			</div>
		</div>
	);
};

export default Login;
const getReceiptsApi = async (formData) => {
    const token = localStorage.getItem('api-token');
    const editedFormData = {
        email: formData.email,
        last_four_digits_of_card: formData.lastFour
    };
    const options = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            token: token
        },
        body: JSON.stringify(editedFormData)
    };
    return await fetch(process.env.REACT_APP_BACK_END_DOMAIN + '/receipts', options);
};

export { getReceiptsApi };
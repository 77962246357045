import { S3 } from '@aws-sdk/client-s3';

const useS3 = () => {
	return new S3({
		credentials: {
			accessKeyId: process.env.REACT_APP_S3_UPLOADER_KEY,
			secretAccessKey: process.env.REACT_APP_S3_UPLOADER_SECRET
		},
		region: 'us-east-1',
	});
};

export default useS3;
import { useEffect, useState } from 'react';
import { useParams, useOutletContext } from 'react-router-dom';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import socketClient from 'socket.io-client';
import moment from 'moment';
import { Carousel } from 'react-responsive-carousel';
import brokenHeart from '../assets/images/broken-heart.png';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styles from './Fundraiser.module.css';
import { getFundraiserApi } from '../api/fundraisers.js';
import LoggedInNav from '../components/logged-in/LoggedInNav.jsx';
import LoggedOutNav from '../components/logged-out/LoggedOutNav.jsx';
import FormHeader from '../components/form/FormHeader.jsx';
import InfoSection from '../components/fundraiser/InfoSection.jsx';
import Comments from '../components/fundraiser/Comments.jsx';
import CountdownClock from '../components/fundraiser/CountdownClock.jsx';
import ActionCard from '../components/fundraiser/ActionCard.jsx';
import DonateModal from '../components/modals/DonateModal.jsx';
import ShareModal from '../components/modals/ShareModal.jsx';
import DonarsModal from '../components/modals/DonarsModal.jsx';
import findArrayIndex from '../utils/findArrayIndex.js';

const io = socketClient(process.env.REACT_APP_BACK_END_DOMAIN === 'https://giverist.com/api' ? 'https://giverist.com' : process.env.REACT_APP_BACK_END_DOMAIN, {
	extraHeaders: {
		token: localStorage.getItem('api-token')
	},
    path: process.env.REACT_APP_BACK_END_DOMAIN === 'https://giverist.com/api' ? '/api/socket.io/' : '/socket.io/'
});

const Fundraiser = () => {
	const { fundraiserId } = useParams();
	const { user } = useOutletContext();
	const [fundraiser, setFundraiser] = useState({});
	const [fundraiserNotFound, setFundraiserNotFound] = useState(false);
	const [status, setStatus] = useState('');
	const [comments, setComments] = useState([]);
	const [showDonateModal, setShowDonateModal] = useState(false);
	const [showShareModal, setShowShareModal] = useState(false);
	const [showDonarsModal, setShowDonarsModal] = useState(false);

	const addDonation = (donation) => {
		const updatedDonationObject = {...donation, created_at: moment().toISOString()};
		setFundraiser((previous) => {
			const previousDonations = previous.donations;
			const updatedTotalDonationsAmount = Number(previous.total_donations_amount) + Number(updatedDonationObject.amount);
			const index = findArrayIndex(previousDonations, 'id', updatedDonationObject.id);
			return index === false ? {...previous, total_donations_amount: updatedTotalDonationsAmount, donations: [...previousDonations, updatedDonationObject]} : previous;
		});
	};

	const getFundraiser = async () => {
		const response = await getFundraiserApi(fundraiserId);
		let body;
		try{
			body = await response.json();
		}
		catch(e){}
		if(response && response.ok){
			setFundraiser(body.data.fundraiser);
		}
		else{
			setFundraiserNotFound(true)
		}
	};

	const sortImages = (a, b) => {
		if(a.sort_order > b.sort_order){
			return 1;
		}
		else if(a.sort_order < b.sort_order){
			return -1;
		}
		return 0;
	};

	const parseComments = () => {
		const commentsArray = []
		if(fundraiser.donations?.length > 0){
			for(let i = 0; i < fundraiser.donations.length; i++){
				if(fundraiser.donations[i].comment){
					commentsArray.push(fundraiser.donations[i]);
				}
			}
		}
		setComments(commentsArray);
	};

	const closeModals = () => {
		setShowDonateModal(false);
		setShowShareModal(false);
		setShowDonarsModal(false)
	};

	useEffect(() => {
		io.on('fundraiser/' + fundraiserId, (data) => {
			addDonation(data);
		});
		getFundraiser();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		parseComments();
		document.title = fundraiser.name ? fundraiser.name : 'Fundraiser';
	}, [fundraiser]);

	return (
		<div>
			{
				user.id ? (
					<LoggedInNav/>
				) : (
					<LoggedOutNav/>
				)
			}
			<div className={styles.container}>
				{
					!fundraiserNotFound ? (
						<div className={styles.fundraiserFound}>
							<div className={styles.leftSide}>
								<FormHeader className={styles.header} text={fundraiser.name}/>
								<InfoSection fundraiser={fundraiser} setStatus={setStatus}/>
								{
									fundraiser.pictures?.length > 0 && (
										<div className={styles.carouselContainer}>
											<Carousel className={styles.carousel} infiniteLoop={true} showStatus={false} showArrows={false} showIndicators={false}
													  showThumbs={fundraiser.pictures?.length > 1} useKeyboardArrows={true}>
												{
													fundraiser.pictures.sort(sortImages).map(picture => (
														<div className={styles.picture} key={picture.url}>
															<img src={process.env.REACT_APP_CLOUDFRONT_S3_DOMAIN + picture.url} alt={picture.url}/>
														</div>
													))
												}
											</Carousel>
										</div>
									)
								}
								<div className={classNames(styles.description, styles.desktopDescription)}>
									{fundraiser.description}
								</div>
								{
									comments.length > 0 && (
										<Comments className={styles.desktopComments} comments={comments}/>
									)
								}
							</div>
							<div className={styles.rightSide}>
								{
									(status === 'in-progress' || status === 'ended') && (
										<CountdownClock className={styles.countdownClock} status={status} endAt={fundraiser.end_at}/>
									)
								}
								<ActionCard className={styles.actionCard} fundraiser={fundraiser} status={status} showDonateModal={() => setShowDonateModal(true)}
											showShareModal={() => setShowShareModal(true)} setShowDonorsModal={(view) => setShowDonarsModal(view)}/>
							</div>
							<div className={classNames(styles.description, styles.mobileDescription)}>
								{fundraiser.description}
							</div>
							{
								comments.length > 0 && (
									<Comments className={styles.mobileComments} comments={comments}/>
								)
							}
						</div>
					) : (
						<div className={styles.fundraiserNotFound}>
							<img className={styles.brokenHeart} src={brokenHeart} alt="Broken Heart"/>
							<div className={styles.fundraiserNotFoundDescription}>
								The fundraiser you are looking for cannot be found
							</div>
						</div>
					)
				}
			</div>
			{
				showDonateModal && createPortal(
					<DonateModal organization={fundraiser.organization} close={closeModals}/>, document.body
				)
			}
			{
				showShareModal && createPortal(
					<ShareModal close={closeModals}/>, document.body
				)
			}
			{
				showDonarsModal && createPortal(
					<DonarsModal view={showDonarsModal} donations={fundraiser.donations} close={closeModals}/>, document.body
				)
			}
		</div>
	)
};

export default Fundraiser;
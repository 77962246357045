import { useState } from "react";
import FileResizer from 'react-image-file-resizer';
import dollar from '../assets/images/dollar.png';
import leftArrowSmall from '../assets/images/left-arrow-small.png';
import rightArrowSmall from '../assets/images/right-arrow-small.png';
import xCircle from '../assets/images/x-circle.png';
import styles from './ImagesSection.module.css';
import findArrayIndex from '../utils/findArrayIndex.js';
import PictureModal from "./modals/PictureModal";

const ImagesSection = (props) => {
    const [imageBase64, setImageBase64] = useState('');
    const [showPictureModal, setShowPictureModal] = useState(false);

	const openUploadFile = () => {
		document.getElementById('upload-image').click();
	};

    const readFile = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.addEventListener('load', () => resolve(reader.result), false);
            reader.readAsDataURL(file);
        });
    };

	const resizeImage = (file) => {
		return new Promise((resolve) => {
			return FileResizer.imageFileResizer(file, 2133, 1200, 'JPEG', 100, 0, (uri) => {
				resolve(uri);
			}, 'file');
		});
	};

	const initializeImage = async () => {
		if(props.formData.images.length >= 5){
			alert('You can upload a maximum of 5 images');
			return;
		}
		let file = document.getElementById('upload-image').files[0];
		if(!file){
			return;
		}
		if(['image/jpeg', 'image/png'].indexOf(file.type) === -1){
			alert('Image must be in JPG or PNG format');
			return;
		}
		if(file.size > 2000000){
			try{
				file = await resizeImage(file);
			}
			catch(e){
				file = false;
			}
		}
		if(file){
            const imageDataUrl = await readFile(file);
            setImageBase64(imageDataUrl);
            setShowPictureModal(true);
		}
		else{
			alert('Something went wrong. Image not added');
		}
	};

    const addImage = (url) => {
        props.setFormData({...props.formData, images: [...props.formData.images, {
            url: url,
            sort_order: props.formData.images.length + 1
        }]});
    };

	const sortImages = (a, b) => {
		if(a.sort_order > b.sort_order){
			return 1;
		}
		else if(a.sort_order < b.sort_order){
			return -1;
		}
		return 0;
	};

	const moveImageForward = (index) => {
		const updatedImages = props.formData.images.slice();
		const firstImageIndex = findArrayIndex(updatedImages, 'sort_order', index + 1);
		const secondImageIndex = findArrayIndex(updatedImages, 'sort_order', index);
		const firstImage = updatedImages[firstImageIndex];
		const secondImage = updatedImages[secondImageIndex];
		firstImage.sort_order = index;
		secondImage.sort_order = index + 1;
		updatedImages[firstImageIndex] = firstImage;
		updatedImages[secondImageIndex] = secondImage;
		props.setFormData({...props.formData, images: updatedImages});
	};

	const moveImageBack = (index) => {
		const updatedImages = props.formData.images.slice();
		const firstImageIndex = findArrayIndex(updatedImages, 'sort_order', index + 1);
		const secondImageIndex = findArrayIndex(updatedImages, 'sort_order', index + 2);
		const firstImage = updatedImages[firstImageIndex];
		const secondImage = updatedImages[secondImageIndex];
		firstImage.sort_order = index + 2;
		secondImage.sort_order = index + 1;
		updatedImages[firstImageIndex] = firstImage;
		updatedImages[secondImageIndex] = secondImage;
		props.setFormData({...props.formData, images: updatedImages});
	};

	const removeImage = (url) => {
		const updatedImages = props.formData.images.slice();
		const index = findArrayIndex(updatedImages, 'url', url);
		const imageToRemoveSortOrder = updatedImages[index].sort_order;
		updatedImages.splice(index, 1);
		for(let i = 0; i < updatedImages.length; i++){
			if(updatedImages[i].sort_order > imageToRemoveSortOrder){
				updatedImages[i].sort_order = updatedImages[i].sort_order - 1;
			}
		}
		props.setFormData({...props.formData, images: updatedImages});
	};

	return (
		<div className={styles.imagesSection}>
			<input id="upload-image" className={styles.uploadImageInput} type="file" accept="image/png, image/jpeg" onChange={initializeImage}
				   onClick={(event) => event.target.value=''}/>
			<div className={styles.uploadImage} onClick={openUploadFile}>
				<img className={styles.dollar} src={dollar} alt="Dollar Sign"/>
				<div className={styles.uploadHeader}>
					Upload Images
				</div>
				<div className={styles.uploadDescription}>
					Upload up to 5 images
				</div>
			</div>
			<div className={styles.images}>
				{
					props.formData.images.length > 0 && props.formData.images.sort(sortImages).map((image, index) => (
						<div className={styles.imageContainer} key={image.url}>
							<img className={styles.image} src={process.env.REACT_APP_CLOUDFRONT_S3_DOMAIN + image.url} alt={image.url}/>
							<span className={styles.sortOrder}>
								{index + 1}
							</span>
							{
								index !== 0 && (
									<span className={styles.leftArrowContainer} onClick={() => moveImageForward(index)}>
										<img className={styles.leftArrow} src={leftArrowSmall} alt="Left Arrow"/>
									</span>
								)
							}
							{
								index !== props.formData.images.length - 1 && (
									<span className={styles.rightArrowContainer} onClick={() => moveImageBack(index)}>
										<img className={styles.rightArrow} src={rightArrowSmall} alt="Right Arrow"/>
									</span>
								)
							}
							<img className={styles.removeImage} src={xCircle} alt="Remove" onClick={() => removeImage(image.url)}/>
						</div>
					))
				}
			</div>
            {
                showPictureModal && (
                    <PictureModal imageBase64={imageBase64} user={props.user} type="fundraiser" update={addImage} close={() => setShowPictureModal(false)}/>
                )
            }
		</div>
	)
};

export default ImagesSection;
import { useLocation } from 'react-router-dom';
import logoWithText from '../../assets/images/logo-with-text.png';
import styles from './LoggedOutNav.module.css';
import LoginButton from './LoginButton.jsx';

const LoggedOutNav = (props) => {
	const { pathname } = useLocation();

	return (
		<div className={styles.container}>
			<div className={styles.nav}>
				<a className={styles.title} href="/">
					<img src={logoWithText} alt="Logo With Text"/>
				</a>
				<LoginButton className={styles.profileContainer} from={props.from ? props.from : pathname}/>
			</div>
		</div>
	)
};

export default LoggedOutNav;
const getAllNotificationsApi = async () => {
	const token = localStorage.getItem('api-token');
	const options = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			token: token
		}
	};
	return await fetch(process.env.REACT_APP_BACK_END_DOMAIN + '/notifications', options);
};

const updateAllNotificationsApi = async () => {
	const token = localStorage.getItem('api-token');
	const options = {
		method: 'PUT',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			token: token
		}
	};
	return await fetch(process.env.REACT_APP_BACK_END_DOMAIN + '/notifications', options);
};

export { getAllNotificationsApi, updateAllNotificationsApi };
const fraction = new Intl.NumberFormat('en-US', {
	minimumFractionDigits: 0,
	maximumFractionDigits: 0,
});

const formatter = new Intl.NumberFormat('en-US', {
	minimumFractionDigits: 2,
});

const formatNumber = (number) => {
	if(number){
		if(number % 1 === 0){
			return fraction.format(number);
		}
		else{
			return formatter.format(number);
		}
	}
	else if(number === 0){
		return 0;
	}
    else{
		return '';
	}
};

export default formatNumber;
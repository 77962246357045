const signupApi = async (formData) => {
	const editedFormData = {
		email: formData.email,
		password: formData.password,
		first_name: formData.firstName,
		last_name: formData.lastName,
		organization: formData.organization
	};
	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(editedFormData)
	};
	return await fetch(process.env.REACT_APP_BACK_END_DOMAIN + '/signup', options);
};

const loginApi = async (formData) => {
	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(formData)
	};
	return await fetch(process.env.REACT_APP_BACK_END_DOMAIN + '/login', options);
};

const verifyApi = async () => {
	const token = localStorage.getItem('api-token');
	const options = {
		method: 'GET',
		headers: {
			token: token
		}
	};
	return await fetch(process.env.REACT_APP_BACK_END_DOMAIN + '/verify', options);
};

const forgotPasswordApi = async (formData) => {
	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(formData)
	};
	return await fetch(process.env.REACT_APP_BACK_END_DOMAIN + '/password-reset', options);
};

const verifyPasswordResetTokenApi = async (token) => {
	const options = {
		method: 'GET',
	};
	return await fetch(process.env.REACT_APP_BACK_END_DOMAIN + `/password-reset?token=${token}`, options);
};

const resetPasswordApi = async (formData, token) => {
	const editedFormData = {
		new_password: formData.password,
		token: token
	};
	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(editedFormData)
	};
	return await fetch(process.env.REACT_APP_BACK_END_DOMAIN + '/reset-password', options);
};

export { signupApi, loginApi, verifyApi, forgotPasswordApi, verifyPasswordResetTokenApi, resetPasswordApi };
import { useState, useEffect } from 'react';
import { verifyApi } from '../api/auth.js';

const useAuth = () => {
	const [authenticated, setAuthenticated] = useState(undefined);
	const [user, setUser] = useState({
		id: null,
		email: null,
		first_name: null,
		last_name: null,
		profile_picture_url: null,
		organization_id: null,
		organization_name: null
	});

	useEffect(() => {
		const verify = async () => {
			const response = await verifyApi();
			setAuthenticated(response && response.ok);
			if(response && response.ok){
				const body = await response.json();
				setUser(body.data.user);
			}
		};
		verify();
	}, []);

	return {
		authenticated: authenticated,
		user: user
	};
};

export default useAuth;
import moment from 'moment';

const parseDatetimeText = (createdAt) => {
	const currentDatetime = moment();
	const createdAtDatetime = moment(createdAt);
	const daysDifference = currentDatetime.diff(createdAtDatetime, 'days');
	if(daysDifference > 0){
		return createdAtDatetime.format('MMMM D, YYYY h:mma');
	}
	else{
		const hoursDifference = currentDatetime.diff(createdAtDatetime, 'hours');
		if(hoursDifference > 0){
			return hoursDifference + (hoursDifference === 1 ? ' hour' : ' hours') + ' ago';
		}
		else{
			const minutesDifference = currentDatetime.diff(createdAtDatetime, 'minutes');
			if(minutesDifference > 0){
				return minutesDifference + (minutesDifference === 1 ? ' minute' : ' minutes') + ' ago';
			}
			else{
				return 'Just now';
			}
		}
	}
};

export default parseDatetimeText;
import { Navigate, Outlet } from 'react-router-dom'
import useAuth from '../../hooks/useAuth.js';

const AuthRoute = () => {
	const { authenticated } = useAuth();

	if(authenticated === undefined){
		return null;
	}
	else{
		return authenticated ? <Navigate to="/dashboard"/> : <Outlet/>;
	}
}

export default AuthRoute;
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import person from '../../assets/images/person.png';
import styles from './LoginButton.module.css';

const LoginButton = (props) => {
	const navigate = useNavigate();
	const classes = classNames(styles.loginButton, props.className);

	const goToLogin = () => {
		navigate('/login', {
			state: {
				from: props.from
			}
		});
	};

	return (
		<button className={classes} onClick={goToLogin}>
			<img className={styles.person} src={person} alt="Person"/>
			Organizer Login
		</button>
	);
};

export default LoginButton;
import {useEffect, useState} from 'react';
import classNames from 'classnames';
import moment from 'moment';
import styles from './CountdownClock.module.css'

const CountdownClock = (props) => {
	const [timeRemaining, setTimeRemaining] = useState({});

	const initCountdownClock = () => {
		const currentDatetime = moment();
		const endAtDatetime = moment(props.endAt);
		const difference = moment.duration(endAtDatetime.diff(currentDatetime));
		setTimeRemaining({
			days: difference.days(),
			hours: difference.hours(),
			minutes: difference.minutes(),
			seconds: difference.seconds()
		});
	};

	useEffect(() => {
		const interval = setInterval(() => {
			initCountdownClock();
		}, 1000);
		return () => clearInterval(interval);
	}, [props.endAt]);

	return (
		<div className={classNames(styles.container, props.className)} style={props.style}>
			<div className={styles.header}>
				{props.status === 'in-progress' ? 'Ends in:' : 'Ended:'}
			</div>
			{
				props.status === 'in-progress' ? (
					<div className={styles.remainingTime}>
						<span className={styles.days}>
							{timeRemaining.days && Math.max(timeRemaining.days, 0)}d
						</span>
								<span className={styles.divider}>
							:
						</span>
								<span className={styles.hours}>
							{timeRemaining.hours && Math.max(timeRemaining.hours, 0)}h
						</span>
								<span className={styles.divider}>
							:
						</span>
								<span className={styles.minutes}>
							{timeRemaining.minutes && Math.max(timeRemaining.minutes, 0)}m
						</span>
								<span className={styles.divider}>
							:
						</span>
								<span className={styles.seconds}>
							{timeRemaining.seconds && Math.max(timeRemaining.seconds, 0)}s
						</span>
					</div>
				) : (
					<div className={styles.endAt}>
						{moment(props.endAt).format('MMM D, YYYY h:mm A')}
					</div>
				)
			}
		</div>
	)
};

export default CountdownClock;
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import styles from './App.module.css';
import Signup from './pages/Signup.jsx';
import Login from './pages/Login.jsx';
import ForgotPassword from './pages/ForgotPassword.jsx';
import ResetPassword from './pages/ResetPassword.jsx';
import Dashboard from './pages/Dashboard.jsx';
import Profile from './pages/Profile.jsx';
import CreateFundraiser from './pages/CreateFundraiser.jsx';
import Fundraisers from './pages/Fundraisers.jsx';
import Fundraiser from './pages/Fundraiser.jsx';
import Receipts from './pages/Receipts.jsx';
import NotFound from './pages/NotFound.jsx';
import ProtectedRoute from './components/auth/ProtectedRoute.jsx';
import AuthRoute from './components/auth/AuthRoute.jsx';
import HybridRoute from './components/auth/HybridRoute.jsx';
import Footer from './components/Footer.jsx';

function App() {
	return (
		<div style={{height: '100%'}}>
			<div className={styles.page}>
				<BrowserRouter>
					<Routes>
						<Route element={<ProtectedRoute/>}>
							<Route path="/dashboard" element={<Dashboard/>}/>
							<Route path="/profile" element={<Profile/>}/>
							<Route path="/profile/:view" element={<Profile/>}/>
							<Route path="/fundraiser" element={<CreateFundraiser/>}/>
						</Route>
						<Route element={<AuthRoute/>}>
							<Route path="/signup" element={<Signup/>}/>
							<Route path="/login" element={<Login/>}/>
							<Route path="/forgot-password" element={<ForgotPassword/>}/>
							<Route path="/reset-password" element={<ResetPassword/>}/>
						</Route>
						<Route element={<HybridRoute/>}>
                            <Route path="/fundraisers" element={<Fundraisers/>}/>
							<Route path="/fundraiser/:fundraiserId" element={<Fundraiser/>}/>
							<Route path="/receipts" element={<Receipts/>}/>
						</Route>
						<Route path="/" element={<Navigate to="/dashboard" replace/>}/>
						<Route path="*" element={<NotFound/>}/>
					</Routes>
				</BrowserRouter>
			</div>
			<Footer/>
		</div>
	);
}

export default App;

import { useEffect, useState } from 'react';
import {useLocation, useOutletContext} from "react-router-dom";
import magnifyingGlassWithQuestionMark from '../assets/images/magnifying-glass-with-quesiton-mark.png';
import styles from './Fundraisers.module.css';
import { getAllFundraisresApi } from '../api/fundraisers.js';
import getFundraiserStatus from "../utils/getFundraiserStatus";
import LoggedInNav from "../components/logged-in/LoggedInNav";
import LoggedOutNav from '../components/logged-out/LoggedOutNav.jsx';
import FormHeader from '../components/form/FormHeader.jsx';
import SearchInput from '../components/SearchInput.jsx';
import SearchFilter from "../components/SearchFilter";
import SearchOrder from '../components/SearchOrder.jsx';
import FundraiserListing from '../components/FundraiserListing.jsx';

const Fundraisers = () => {
    const location = useLocation();
    const { user } = useOutletContext();
    const [fundraisers, setFundraisers] = useState([]);
    const [filteredFundraisers, setFilteredFundraisers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filterBy, setFilterBy] = useState('inProgress');
    const [filterByLabel, setFilterByLabel] = useState('In progress');
    const filterOptions = [{
        label: 'In progress',
        value: 'inProgress'
    }, {
        label: 'Not started',
        value: 'notStarted'
    }, {
        label: 'Ended',
        value: 'ended'
    }];
    const [sortBy, setSortBy] = useState('newest');
    const [sortByLabel, setSortByLabel] = useState('Newest');
    const sortOptions = [{
        label: 'Newest',
        value: 'newest'
    }, {
        label: 'Oldest',
        value: 'oldest'
    }, {
        label: 'Most Raised',
        value: 'amount'
    }];

    const getFundraisers = async() => {
        const response = await getAllFundraisresApi();
        const body = await response.json();
        if(response && response.ok){
            setFundraisers(body.data.fundraisers);
        }
    };

    const updateFilter = (label, value) => {
        setFilterByLabel(label);
        setFilterBy(value);
    };

    const updateSort = (label, value) => {
        setSortByLabel(label);
        setSortBy(value);
    };

    const sortByOrder = (a, b) => {
        if(sortBy === 'newest'){
            if(a.end_at > b.end_at){
                return -1;
            }
            else if(a.end_at < b.end_at){
                return 1;
            }
            return 0;
        }
        else if(sortBy === 'oldest'){
            if(a.end_at < b.end_at){
                return -1;
            }
            else if(a.end_at > b.end_at){
                return 1;
            }
            return 0;
        }
        else if(sortBy === 'amount'){
            return b.total_donations_amount - a.total_donations_amount;
        }
    };

    useEffect(() => {
        getFundraisers();
        document.title = 'Fundraisers';
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setFilteredFundraisers(fundraisers.filter(((fundraiser) => {
            if(filterByLabel !== getFundraiserStatus(fundraiser.start_at, fundraiser.end_at)['status']){
                return false;
            }
            if(searchTerm){
                return fundraiser.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1  ||
                    fundraiser.organization_name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
            }
            else{
                return true;
            }
        })));
    }, [fundraisers, filterByLabel, searchTerm]);

    return (
        <div>
            {
                user.id ? (
                    <LoggedInNav/>
                ) : (
                    <LoggedOutNav from={location.state?.from}/>
                )
            }
            <div className={styles.container}>
                <div className={styles.fundraisers}>
                    <div className={styles.topSection}>
                        <FormHeader className={styles.fundraisersHeader} text="Fundraisers"/>
                        <div className={styles.rightSide}>
                            <SearchInput className={styles.searchInput} placeholder="Search" value={searchTerm} update={(event) => setSearchTerm(event.target.value)}/>
                            <SearchFilter className={styles.searchFilter} label={filterByLabel} options={filterOptions} value={filterBy} update={updateFilter}/>
                            <SearchOrder className={styles.searchOrder} label={sortByLabel} options={sortOptions} value={sortBy} update={updateSort}/>
                        </div>
                    </div>
                    <div className={styles.bottomSection}>
                        {
                            filteredFundraisers.length > 0 && filteredFundraisers.sort(sortByOrder).map(fundraiser => (
                                <FundraiserListing className={styles.fundraiser} fundraiser={fundraiser} key={'fundraiser-' + fundraiser.id}/>
                            ))
                        }
                        {
                            filteredFundraisers.length === 0 && (
                                <div className={styles.noFundraisers}>
                                    <img className={styles.magnifyingGlassWithQuestionMark} src={magnifyingGlassWithQuestionMark} alt="Magnifying Glass With Question Mark"/>
                                    <div className={styles.noFundraisersDescription}>
                                        No fundraisers found
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Fundraisers;
import classNames from 'classnames';
import styles from './FormButton.module.css';

const FormButton = (props) => {
	const classes = classNames(styles.button, props.className, {
		[styles.disabled]: props.disabled
	});

	return (
		<button className={classes} style={props.style} type="submit" disabled={props.disabled}>
			{props.text} {props.children}
		</button>
	);
};

export default FormButton;
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import signup from '../assets/images/signup.png';
import styles from './Signup.module.css';
import { signupApi } from '../api/auth.js';
import ButtonLink from '../components/ButtonLink.jsx';
import FormHeader from '../components/form/FormHeader.jsx';
import FormInput from '../components/form/FormInput.jsx';
import FormButton from '../components/form/FormButton.jsx';
import FormError from '../components/form/FormError.jsx';

const Signup = () => {
	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		email: '',
		password: '',
		confirmPassword: '',
		firstName: '',
		lastName: '',
		organization: ''
	});
	const initialErrorObject = {
		email: false,
		password: false,
		confirmPassword: false,
		firstName: false,
		lastName: false,
		organization: false,
		apiError: false,
	};
	const [errorObject, setErrorObject] = useState(initialErrorObject);
	const inputsMessagesErrorObject = {
		email: 'Missing email',
		password: 'Missing password',
		passwordLength: 'Password must be at least 6 characters',
		confirmPassword: 'Passwords do not match',
		firstName: 'Missing first name',
		lastName: 'Missing last name',
		organization: 'Missing organization'
	};
	const [signupInProgress, setSignupInProgress] = useState(false);

	const checkForEmptyInputs = () => {
		setErrorObject(initialErrorObject);
		const newErrorObject = structuredClone(initialErrorObject);
		let foundError = false;
		for(const key of Object.keys(formData)){
			if(!formData[key] && key !== 'confirmPassword'){
				newErrorObject[key] = inputsMessagesErrorObject[key];
				foundError = true;
			}
		}
		setErrorObject(newErrorObject);
		return foundError;
	};

	const checkPasswordLength = () => {
		if(formData.password.length < 6){
			setErrorObject((prevState) => ({...prevState, password: inputsMessagesErrorObject.passwordLength}));
			return false;
		}
		return true;
	};

	const checkPasswordsMatch = () => {
		if(formData.password !== formData.confirmPassword){
			setErrorObject((prevState) => ({...prevState, confirmPassword: inputsMessagesErrorObject.confirmPassword}));
			return false;
		}
		return true;
	};

	const submit = async (event) => {
		event.preventDefault();
		setErrorObject(initialErrorObject);
		setSignupInProgress(true);
		if(checkForEmptyInputs()){
			setSignupInProgress(false);
			return;
		}
		if(!checkPasswordLength() || !checkPasswordsMatch()){
			setSignupInProgress(false);
			return;
		}
		const response = await signupApi(formData);
		let body;
		try{
			body = await response.json();
		}
		catch(e){}
		if(response && response.ok){
			localStorage.setItem('api-token', body.data.token);
			navigate('/dashboard');
		}
		else{
			const error = body?.error ? body.error : 'Signup failed';
			setErrorObject((prevState) => ({...prevState, apiError: error}));
		}
		setSignupInProgress(false);
	};

	useEffect(() => {
		document.title = 'Signup';
	}, []);

	useEffect(() => {
		const errorElements = document.getElementsByClassName('error');
		if(errorElements.length > 0){
			errorElements[0].scrollIntoView({
				block: 'start',
				behavior: 'smooth',
			});
		}
	}, [errorObject]);

	return (
		<div className={styles.container}>
			<div className={styles.formContainer}>
				<form className={styles.form} onSubmit={submit}>
					<FormHeader className={styles.header} text="Welcome to GIVERIST!"/>
					<div className={styles.formNamesRow}>
						<FormInput className={styles.name} label="First name" type="text" placeholder="ie. John" value={formData.firstName}
								   update={(event) => setFormData({...formData, firstName: event.target.value})} error={errorObject.firstName} required/>
						<FormInput className={styles.name} label="Last name" type="text" placeholder="ie. Smith" style={{marginLeft: 'auto'}} value={formData.lastName}
								   update={(event) => setFormData({...formData, lastName: event.target.value})} error={errorObject.lastName} required/>
					</div>
					<FormInput className={styles.singleLineInput} label="Email" type="email" placeholder="ie. johnsmith@gmail.com" value={formData.email}
							   update={(event) => setFormData({...formData, email: event.target.value})} error={errorObject.email} required/>
					<FormInput className={styles.singleLineInput} label="Password" type="password" placeholder="****************" value={formData.password}
							   update={(event) => setFormData({...formData, password: event.target.value})} error={errorObject.password} required/>
					<FormInput className={styles.singleLineInput} label="Confirm password" type="password" placeholder="****************"
							   value={formData.confirmPassword} update={(event) => setFormData({...formData, confirmPassword: event.target.value})} error={errorObject.confirmPassword} required/>
					<FormInput className={styles.singleLineInput} label="Organization" type="text" placeholder="ie. Yale University"
							   value={formData.organization} update={(event) => setFormData({...formData, organization: event.target.value})} error={errorObject.organization} maxlength={70} required/>
					{
						errorObject.apiError && (
							<FormError error={errorObject.apiError}/>
						)
					}
					<FormButton className={styles.button} text="Sign up" disabled={signupInProgress}/>
				</form>
			</div>
			<div className={styles.banner}>
				<img className={styles.image} src={signup} alt="Signup"/>
				<div className={styles.bottomBar}>
					<span className={styles.bottomBarText}>
						Already have an account?
					</span>
					<ButtonLink className={styles.loginButton} href="/login" text="Login"/>
				</div>
			</div>
		</div>
	);
};

export default Signup;
const startTimeOptions = [{
	name: '1:00 AM',
	value: '01:00'
}, {
	name: '2:00 AM',
	value: '02:00'
}, {
	name: '3:00 AM',
	value: '03:00'
}, {
	name: '4:00 AM',
	value: '04:00'
}, {
	name: '5:00 AM',
	value: '05:00'
}, {
	name: '6:00 AM',
	value: '06:00'
}, {
	name: '7:00 AM',
	value: '07:00'
}, {
	name: '8:00 AM',
	value: '08:00'
}, {
	name: '9:00 AM',
	value: '09:00'
}, {
	name: '10:00 AM',
	value: '10:00'
}, {
	name: '11:00 AM',
	value: '11:00'
}, {
	name: '12:00 PM',
	value: '12:00'
}, {
	name: '1:00 PM',
	value: '13:00'
}, {
	name: '2:00 PM',
	value: '14:00'
}, {
	name: '3:00 PM',
	value: '15:00'
}, {
	name: '4:00 PM',
	value: '16:00'
}, {
	name: '5:00 PM',
	value: '17:00'
}, {
	name: '6:00 PM',
	value: '18:00'
}, {
	name: '7:00 PM',
	value: '19:00'
}, {
	name: '8:00 PM',
	value: '20:00'
}, {
	name: '9:00 PM',
	value: '21:00'
}, {
	name: '10:00 PM',
	value: '22:00'
}, {
	name: '11:00 PM',
	value: '23:00'
}, {
	name: '12:00 AM',
	value: '24:00'
}];

const startTimezoneOptions = [{
	name: 'Eastern Standard Time (EST)',
	value: 'America/New_York'
}, {
	name: 'Central Standard Time (CST)',
	value: 'America/Chicago'
}, {
	name: 'Mountain Standard Time (MST)',
	value: 'America/Denver'
}, {
	name: 'Pacific Standard Time (PST)',
	value: 'America/Los_Angeles'
}];

const lengthsOptions = [{
	name: '3 days',
	value: '3'
}, {
	name: '7 days',
	value: '7'
}, {
	name: '14 days',
	value: '14'
}, {
	name: '21 days',
	value: '21'
}];

export {startTimeOptions, startTimezoneOptions, lengthsOptions};
import classNames from 'classnames';
import styles from './ButtonLink.module.css';

const ButtonLink = (props) => {
	const classes = classNames(styles.buttonLink, props.className, {
		[styles.dark]: props.dark
	});

	return (
		<a className={classes} href={props.href} target={props.target ? props.target : '_self'}>
			{props.text}
		</a>
	);
};

export default ButtonLink;
import classNames from 'classnames';
import moment from 'moment';
import coin from '../../assets/images/coin.png'
import styles from './Comment.module.css';
import formatNumber from '../../utils/formatNumber.js';

const Comment = (props) => {
	return (
		<div className={classNames(styles.container, props.className)} style={props.style}>
			<div className={styles.name}>
				{props.comment?.name ? props.comment?.name : 'Anonymous'}
			</div>
			<div className={styles.info}>
				<img className={styles.coin} src={coin} alt="Coin"/>
				<span className={styles.amount}>
					${formatNumber(props.comment?.amount)}
				</span>
				<span className={styles.dot}/>
				<span className={styles.datetime}>
					{moment(props.comment?.created_at).format('MMMM D, YYYY h:mma')}
				</span>
			</div>
			<div className={styles.comment}>
				{props.comment?.comment}
			</div>
		</div>
	)
};

export default Comment;
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import coins from '../../assets/images/coins.png';
import share from '../../assets/images/share.png';
import receipt from '../../assets/images/receipt.png';
import stars from '../../assets/images/stars.png';
import styles from './ActionCard.module.css';
import formatNumber from '../../utils/formatNumber.js';
import Button from '../Button.jsx';
import DonationRecord from './DonationRecord.jsx';

const ActionCard = (props) => {
	const [progressPercent, setProgressPercent] = useState(0);

	const getProgressPercent = () => {
		if(!props.fundraiser.total_donations_amount){
			return;
		}
		setProgressPercent(Math.min((props.fundraiser.total_donations_amount / props.fundraiser.goal_amount) * 100, 100));
	};

	const sortDonations = (a, b) => {
		if(a.created_at > b.created_at){
			return -1;
		}
		else if(a.created_at < b.created_at){
			return 1;
		}
		return 0;
	};

	useEffect(() => {
		getProgressPercent();
	}, [props.fundraiser]);

	return (
		<div className={classNames(styles.container, props.className)} style={props.style}>
			<div>
				<span className={styles.totalRaisedAmount}>
					${formatNumber(props.fundraiser.total_donations_amount) || 0}
				</span>
				<span className={styles.totalRaisedtext}>
					USD raised of ${formatNumber(props.fundraiser.goal_amount)} goal
				</span>
			</div>
			<div className={styles.progress}>
				<div className={styles.progressBar}>
					<div className={styles.progressMade} style={{width: progressPercent + '%'}}/>
				</div>
				<span className={styles.totalDonations}>
					{props.fundraiser?.donations?.length} donations
				</span>
			</div>
			<div className={styles.donateShareButtons}>
				{
					props.status === 'in-progress' && (
						<Button className={styles.donateButton} text="Donate now" image={coins} dark={true} onClick={props.showDonateModal}/>
					)
				}
				{
					props.status !== 'ended' && (
						<Button className={styles.shareButton} text="Share" image={share} dark={false} onClick={props.showShareModal}/>
					)
				}
				{
					props.status === 'ended' && (
						<a className={styles.receiptLink} href="/receipts">
							<img className={styles.receipt} src={receipt} alt="Receipt"/>
							Request donation receipt
						</a>
					)
				}
			</div>
			{
				props.fundraiser?.donations?.length > 0 && (
					<div className={styles.donations}>
						{
							props.fundraiser.donations.sort(sortDonations).slice(0, 5).map(donation => (
								<DonationRecord donation={donation} key={'donation-' + donation.id}/>
							))
						}
					</div>
				)
			}
			{
				props.status !== 'scheduled' && (
					<div className={styles.donationsButtons}>
						<Button className={styles.seeAllButton} text="See all" dark={false} onClick={() => props.setShowDonorsModal('newest')}/>
						<Button className={styles.topDonorsButton} text="Top donors" image={stars} dark={false} onClick={() => props.setShowDonorsModal('amount')}/>
					</div>
				)
			}
		</div>
	)
};

export default ActionCard;
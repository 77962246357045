import coin from '../../assets/images/coin.png';
import styles from './DonationRecord.module.css';
import formatNumber from '../../utils/formatNumber.js';
import parseDatetimeText from '../../utils/parseDatetimeText.js';

const DonationRecord = (props) => {
	return (
		<div className={styles.donation}>
			<img className={styles.coin} src={coin} alt="Coin"/>
			<div>
				<div className={styles.sentence}>
					<span className={styles.name}>
						{props.donation.name ? props.donation.name : 'Anonymous'}
					</span> made a ${formatNumber(props.donation.amount)} donation
				</div>
				<div className={styles.datetime}>
					{parseDatetimeText(props.donation.created_at)}
				</div>
			</div>
		</div>
	)
};

export default DonationRecord;
import {useEffect, useState} from 'react';
import styles from './Security.module.css';
import { updateUserPasswordApi } from '../../api/users.js';
import FormInput from '../form/FormInput.jsx';
import FormButton from '../form/FormButton.jsx';
import FormButtonSuccess from '../form/FormButtonSuccess.jsx';
import FormError from '../form/FormError.jsx';

const Security = (props) => {
	const [formData, setFormData] = useState({
		currentPassword: '',
		newPassword: '',
		confirmNewPassword: ''
	});
	const initialErrorObject = {
		currentPassword: false,
		newPassword: false,
		passwordLength: false,
		confirmNewPassword: false,
		apiError: false
	};
	const [errorObject, setErrorObject] = useState(initialErrorObject);
	const inputsMessagesErrorObject = {
		currentPassword: 'Missing current password',
		newPassword: 'Missing new password',
		passwordLength: 'Password must be at least 6 characters',
		confirmNewPassword: 'Passwords do not match'
	};
	const [saveInProgress, setSaveInProgress] = useState(false);
	const [saveSuccessful, setSaveSuccessful] = useState(false);

	const checkForEmptyInputs = () => {
		setErrorObject(initialErrorObject);
		const newErrorObject = structuredClone(initialErrorObject);
		let foundError = false;
		for(const key of Object.keys(formData)){
			if(!formData[key]){
				newErrorObject[key] = inputsMessagesErrorObject[key];
				foundError = true;
			}
		}
		setErrorObject(newErrorObject);
		return foundError;
	};

	const checkPasswordLength = () => {
		if(formData.newPassword.length < 6){
			setErrorObject((prevState) => ({...prevState, newPassword: inputsMessagesErrorObject.passwordLength}));
			return false;
		}
		return true;
	};

	const checkPasswordsMatch = () => {
		if(formData.newPassword !== formData.confirmNewPassword){
			setErrorObject((prevState) => ({...prevState, confirmNewPassword: inputsMessagesErrorObject.confirmNewPassword}));
			return false;
		}
		return true;
	};

	const submit = async (event) => {
		event.preventDefault();
		setErrorObject(initialErrorObject);
		setSaveInProgress(true);
		if(checkForEmptyInputs()){
			setSaveInProgress(false);
			return;
		}
		if(!checkPasswordLength() || !checkPasswordsMatch()){
			setSaveInProgress(false);
			return;
		}
		const response = await updateUserPasswordApi(formData);
		let body;
		try{
			body = await response.json();
		}
		catch(e){}
		if(response && response.ok){
			setSaveSuccessful(true);
		}
		else{
			const error = body?.error ? body.error : 'Update failed';
			setErrorObject((prevState) => ({...prevState, apiError: error}));
		}
		setSaveInProgress(false);
	};

	useEffect(() => {
		const errorElements = document.getElementsByClassName('error');
		if(errorElements.length > 0){
			errorElements[0].scrollIntoView({
				block: 'start',
				behavior: 'smooth',
			});
		}
	}, [errorObject]);

	return (
		<form className={props.className} onSubmit={submit}>
			<FormInput className={styles.singleLineInput} label="Current password" type="password" placeholder="****************" value={formData.currentPassword}
					   update={(event) => setFormData({...formData, currentPassword: event.target.value})}
					   error={errorObject.currentPassword} required/>
			<FormInput className={styles.singleLineInput} label="New password" type="password" placeholder="****************" value={formData.newPassword}
					   update={(event) => setFormData({...formData, newPassword: event.target.value})}
					   error={errorObject.newPassword} required/>
			<FormInput className={styles.singleLineInput} label="Confirm password" type="password" placeholder="****************" value={formData.confirmNewPassword}
					   update={(event) => setFormData({...formData, confirmNewPassword: event.target.value})}
					   error={errorObject.confirmNewPassword} required/>
			{
				errorObject.apiError && (
					<FormError error={errorObject.apiError}/>
				)
			}
			{
				!saveSuccessful && (
					<FormButton className={styles.button} text="Update password" disabled={saveInProgress}/>
				)
			}
			{
				saveSuccessful && (
					<FormButtonSuccess className={styles.button} text="Password updated"/>
				)
			}
		</form>
	);
};

export default Security;